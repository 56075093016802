import React from 'react';
import { SEO, Layout, Header, Footer, AircraftCard, GridContainer } from '../../components';
//
import cssModule from './index.module.scss';

export default ({ pageContext: ctx }) => {
  const { heroSection, description, aircrafts, metadata, translationPath, i18n_lang: lang } = ctx;

  return (
    <Layout>
      <SEO {...metadata.metadata} />
      <Header {...heroSection} lang={lang} translationPath={translationPath} />

      <section>
        <GridContainer className={cssModule.pricing} large="auto / 1fr 1fr 1fr">
          <div className={cssModule.pricingInfo}>
            {description && description.map(item => <p>{item}</p>)}
          </div>
          {aircrafts &&
            aircrafts.map(aircraft => (
              <AircraftCard {...aircraft} key={aircraft.slug} lang={lang} />
            ))}
        </GridContainer>
      </section>
      <Footer lang={lang} />
    </Layout>
  );
};
